import React from 'react';
import { IOpdracht } from 'interfaces/opdracht';
import { DefaultEffects, Icon, IStackStyles, NeutralColors, SharedColors, Stack, Text } from '@fluentui/react';
import InputCard from 'components/InputCard';
import './index.scss';
import { stringToLocaleDateString } from 'lib/internationalization';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import { OpdrachtSoort, OpdrachtSoortLabelMap } from 'enums/opdrachtSoort';
import MailTo from 'components/MailTo';
import primaryTheme from 'themes/primary';
import Call from 'components/Call';
import OpenMap from 'components/OpenMap';

interface IProps {
    opdracht: IOpdracht;
}

export const Samenvatting = ({opdracht}: IProps) => {

    const decodeHtmlEntities = (str: string) => {
        const txt = document.createElement('textarea');
        txt.innerHTML = str;
        return txt.value;
    }

    return (
        <div className="samenvatting flex-container flex-column flex-grow">
            <Stack horizontal wrap tokens={{childrenGap: 10}} styles={stackStyles}>
                <InputCard title="" className="samenvatting-card">
                    <div className="samenvatting-soort-wrapper">
                        <div style={{
                            backgroundColor: getColorFromOpdrachtsoort(opdracht.soort),
                            color: NeutralColors.white,
                            fontWeight: 600,
                            padding: '6px 8px',
                            display: 'inline-block',
                            fontSize: '11px',
                            marginTop: '-2px',
                            marginLeft: '-6px',
                            boxShadow: DefaultEffects.elevation4,
                        }}>
                            {OpdrachtSoortLabelMap[opdracht.soort]}
                        </div>

                    </div>
                    {/*<div className="onderdeel">{OpdrachtSoortLabelMap[opdracht.soort]}</div>*/}
                    <div className="onderdeel">Omschrijving</div>
                    <span dangerouslySetInnerHTML={{ __html: decodeHtmlEntities(opdracht.vrijeTekst ?? '') }}></span>
                    <div className="onderdeel">Onderhoudslocatie</div>
                    <Text>{renderMap(opdracht.straatnaam, opdracht.plaats, opdracht.huisnummer)}
                        {`${opdracht.straatnaam} ${opdracht.huisnummer || ''} ${opdracht.huisletter || ''} ${opdracht.huisnummerToevoeging || ''}, `}
                        {opdracht.postcode} {opdracht.plaats}
                    </Text>
                    <div className="onderdeel">Contactpersoon locatie</div>
                    <Text>{opdracht.contactpersoon}</Text>
                    {opdracht.telefoonnummer1 &&
                        <Text>{renderCall(opdracht.telefoonnummer1)} {opdracht.telefoonnummer1}</Text>}
                    {opdracht.telefoonnummer2 &&
                        <Text>{renderCall(opdracht.telefoonnummer2)} {opdracht.telefoonnummer2}</Text>}
                    {opdracht.emailadres1 && <Text>{renderMailTo(opdracht.emailadres1)} {opdracht.emailadres1}</Text>}
                    {opdracht.emailadres2 && <Text>{renderMailTo(opdracht.emailadres2)} {opdracht.emailadres2}</Text>}
                    <div className="onderdeel">Contactpersoon opdrachtgever</div>
                    {opdracht.partijOpdrachtGever &&
                        <>
                            <Text>{opdracht.partijOpdrachtGever.naam}</Text>
                            {opdracht.partijOpdrachtGever.contactgegevens && (
                                opdracht.partijOpdrachtGever.contactgegevens.map((contactgegeven) => (
                                    <>
                                        <Text>{contactgegeven.contactpersoon}</Text>
                                        {contactgegeven.telefoonnummer &&
                                            <Text>{renderCall(contactgegeven.telefoonnummer)} {contactgegeven.telefoonnummer}</Text>}
                                        {contactgegeven.emailadres &&
                                            <Text>{renderMailTo(contactgegeven.emailadres)} {contactgegeven.emailadres}</Text>}
                                    </>
                                )))
                            }
                        </>
                    }
                    <div className="onderdeel">Datum uiterlijk gereed</div>
                    <Text>{stringToLocaleDateString(opdracht.gewensteUitvoerdatum)}</Text>
                    {opdracht.afspraakVan && (
                        <>
                            <div className="onderdeel">{opdracht.soort === 'MUT' ? 'Planning' : 'Afspraak'}</div>
                            <Text>{format(parseISO(opdracht.afspraakVan ?? ''), 'dd-MM-yyyy HH:mm')}</Text>
                            <Text>{format(parseISO(opdracht.afspraakTot ?? ''), 'dd-MM-yyyy HH:mm')}</Text>
                        </>
                    )}
                    <div className="onderdeel">Opdrachtregels</div>
                    {opdracht.regels && opdracht.regels.map((regel) => (
                        <div className="opdracht-regel">
                            <Text block
                                  className="opdracht-regel-kop">{regel.locatie} | {regel.element} | {regel.defect}</Text>
                            {regel.omschrijvingLang && <Text block>{regel.omschrijvingLang}</Text>}
                            {!regel.omschrijvingLang && regel.omschrijving && <Text block>{regel.omschrijving}</Text>}
                            {regel.vrijeTekst && <Text block>{regel.vrijeTekst}</Text>}
                        </div>
                    ))
                    }
                </InputCard>
            </Stack>
        </div>
    );
};

const getColorFromOpdrachtsoort = (opdrachtSoort: OpdrachtSoort): string => {
    switch (opdrachtSoort) {
        case OpdrachtSoort.INS:
            return SharedColors.orange20;
        case OpdrachtSoort.MUT:
            return SharedColors.green10;
        case OpdrachtSoort.REP:
            return SharedColors.cyanBlue10;
    }
};

const renderMailTo = (email: string): JSX.Element => {
    return (
        <MailTo email={email} style={prefixContactButtons}>
            <Icon iconName="mail" styles={{root: {color: primaryTheme.palette.themePrimary}}}/>
        </MailTo>
    );
};

const renderCall = (number: string): JSX.Element => {
    return (
        <Call phoneNumber={number} style={prefixContactButtons}>
            <Icon iconName="phone" styles={{root: {color: primaryTheme.palette.themePrimary}}}/>
        </Call>
    );
};

const renderMap = (street: string, city: string, houseNumber?: string): JSX.Element => {
    return (
        <OpenMap street={street} city={city} houseNumber={houseNumber} style={prefixContactButtons}>
            <Icon iconName="mapPin" styles={{root: {color: primaryTheme.palette.themePrimary}}}/>
        </OpenMap>
    );
};

const prefixContactButtons: React.CSSProperties = {
    verticalAlign: 'middle',
    paddingRight: 4,
};


const stackStyles: IStackStyles = {
    inner: {
        display: 'flex',
        height: '100%',
        width: '100%',
        padding: 10,
        overflow: 'auto',
    },
    root: {
        display: 'flex',
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
    },
};
